<script>
import Terms from '@/components/TermsConsentimento.vue'

export default {
  name: 'TermsConsentimentoView',
  components: {
    Terms
  }
};
</script>

<template>
  <div class="terms-page">
    <terms :showBtn="false" :showCloseBtn="false"/>
  </div>
</template>

<style lang="postcss" scoped>
.terms-page {
  @media (min-width: 992px) {
    background-color: var(--color-blue-medium);
  }
  .terms{
    input{
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    a{
      color: var(--color-green);
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
